export function FooterMCAStepDefault() {
  return (
    <p>
      <small>
        Le contrat sâge autonomie vous est proposé par la Mutuelle
        Complémentaire d&apos;Alsace, mutuelle soumise aux dispositions du
        livre II du Code de la Mutualité, dont le siège social est situé 6
        route de Rouffach CS 40062 - 68027 Colmar Cedex et immatriculée au
        répertoire SIRENE sous le N° SIREN 778 900 027. Elle agit en qualité
        de distributeur de Tutélaire.
        <br/>
        Mutuelle soumise aux dispositions du livre II du Code de la mutualité,
        dont le siège social est situé 157 avenue de France – 75013 Paris et
        immatriculée sous le numéro Siren 775 682 164.
        <br/>
        Les garanties dépendance sont assurées et réalisées par la mutuelle
        Tutélaire. Les garanties service et protection juridiques sont
        assurées et réalisées par Solucia Service et Protection Juridiques,
        société anonyme à directoire et conseil de surveillance au capital
        social de 9 600 000 € régie par le Code des assurances, dont le siège
        social est situé 111 avenue de France – CS 51519 – 75634 Paris cedex
        13 et immatriculée au RCS de Paris sous le numéro Siren 481 997 708.
        <br/>
        Les garanties d&apos;assistance sont assurées et réalisées par
        Ressources Mutuelles Assistances, union d&apos;assistance soumise aux
        dispositions du Livre II du Code de la mutualité, dont le siège social
        est situé 46 rue du Moulin - CS 32427 - 44124 Vertou Cedex et
        immatriculée sous le numéro Siren 444 269 682.
      </small>
    </p>
  );
}
