import { environment } from "@src/MarqueBlanche";
import { StepName } from "@src/Steps/Steps";
import {
  FooterLegalTutelaire,
  ProtectionDonneesTutelaire,
} from "./FooterLegalTutelaire";
import { FooterLegalMSP, ProtectionDonneesMSP } from "./FooterLegalMSP";
import { FooterLegalMCA, ProtectionDonneesMCA } from "./FooterLegalMCA";
import {
  FooterLegalMutami,
  ProtectionDonneesMutami,
} from "./FooterLegalMutami";
import "./footerLegal.scss";

type FooterLegalProps = {
  stepName: StepName;
};

export function ProtectionDonnees() {
  if (environment.is.MSP) return <ProtectionDonneesMSP />;
  if (environment.is.MCA) return <ProtectionDonneesMCA />;
  if (environment.is.MUTAMI) return <ProtectionDonneesMutami />;
  return <ProtectionDonneesTutelaire />;
}

export function Footer({ stepName }: { stepName: StepName }) {
  if (environment.is.MSP) {
    return <FooterLegalMSP stepName={stepName} />;
  }

  if (environment.is.MCA) {
    return <FooterLegalMCA stepName={stepName} />;
  }

  if (environment.is.MUTAMI) {
    return <FooterLegalMutami stepName={stepName} />;
  }

  return <FooterLegalTutelaire stepName={stepName} />;
}

export function FooterLegal({ stepName }: FooterLegalProps) {
  return (
    <div className="footer-legal">
      <div className="stepper__centered-container stepper__centered-container--grey landing-step__second-part">
        <div className="stepper__centered-content step__grid">
          <Footer stepName={stepName} />
        </div>
      </div>
    </div>
  );
}
