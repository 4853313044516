import { FooterMCAStepAboutYou } from "@src/Footer/MCA/FooterMCAStepAboutYou";
import { FooterMCAStepBirthDate } from "@src/Footer/MCA/FooterMCAStepBirthDate";
import { FooterMCAStepContactInfos } from "@src/Footer/MCA/FooterMCAStepContactInfos";
import {FooterMCAStepDefault} from "@src/Footer/MCA/FooterMCAStepDefault";
import { FooterMCAStepEligibilite } from "@src/Footer/MCA/FooterMCAStepEligibilite";
import { FooterMCAStepMandatPrelevement } from "@src/Footer/MCA/FooterMCAStepMandatPrelevement";
import { FooterMCAStepNumSecu } from "@src/Footer/MCA/FooterMCAStepNumSecu";
import { FooterMCAStepPiecesJustificatives } from "@src/Footer/MCA/FooterMCAStepPiecesJustificatives";
import { StepName } from "@src/Steps/Steps";
import React from "react";

type FooterByStepNameProps = {
  stepName: StepName;
};

export function FooterMCAByStepName({ stepName }: FooterByStepNameProps) {
  switch (stepName) {
    case "BIRTHDATE":
      return <FooterMCAStepBirthDate />;
    case "CONTACT_INFOS":
      return <FooterMCAStepContactInfos />;
    case "ELIGIBILITE":
      return <FooterMCAStepEligibilite />;
    case "ABOUT_YOU":
      return <FooterMCAStepAboutYou />;
    case "NUM_SECU":
      return <FooterMCAStepNumSecu />;
    case "MANDAT_PRELEVEMENT":
      return <FooterMCAStepMandatPrelevement />;
    case "PIECES_JUSTIFICATIVES":
      return <FooterMCAStepPiecesJustificatives />;
    default:
      return <FooterMCAStepDefault />;
  }
}
