import { MacroStep } from "./Steps/Steps";
import "./topbar.scss";
import { environment } from "@src/MarqueBlanche";

export type TopBarProps = {
  activeMacroStep: MacroStep;
};

const getTopbarContratInfoDiv = () => {
  if (environment.is.MUTAMI || environment.is.MSP) {
    return (
      <div className="topbar__contract-infos">
        <img src="/logo-sage.png" alt="logo-sage" />
        <div style={{ marginTop: "-26px" }}>
          <p>
            <p className="topbar__contract-infos__contract-name">
              Contrat sâge autonomie
            </p>
            <p className="topbar__contract-infos__second-line">
              Un contrat assuré par Tutélaire
            </p>
          </p>
        </div>
      </div>
    );
  }
  return (
    <div className="topbar__contract-infos">
      <img src="/logo-sage.png" alt="logo-sage" />
      <div>
        <p>Contrat sâge autonomie</p>
      </div>
    </div>
  );
};

export function TopBar({ activeMacroStep }: TopBarProps) {
  return (
    <div className="topbar">
      <div className="topbar__logo">
        {environment.is.default && (
          <img src="/logo-tutelaire.png" alt="logo-tutelaire" />
        )}
        {environment.is.MSP && (
          <a href="https://mutuelle-msp.fr/">
            <img src="/logo-MSP.png" alt="logo-MSP" />
          </a>
        )}
        {environment.is.MCA && (
          <a href="https://www.mc-alsace.fr/">
            <img src="/logo-MCA.svg" alt="logo-MCA" />
          </a>
        )}
        {environment.is.MUTAMI && (
          <a href="https://www.mutuelle-mutami.fr/">
            <img src="/logo-MUTAMI.png" alt="logo-Mutami" />
          </a>
        )}
      </div>
      <div className="topbar__progress">
        <p
          className="topbar__progress-item"
          data-active={activeMacroStep === MacroStep.Simulate}
        >
          <span>01</span> Simuler mon tarif
        </p>
        <span className="topbar__progress-line" />
        <p
          className="topbar__progress-item"
          data-active={activeMacroStep === MacroStep.Receive}
        >
          <span>02</span> Recevoir mon devis
        </p>
        <span className="topbar__progress-line" />

        <p
          className="topbar__progress-item"
          data-active={activeMacroStep === MacroStep.Subscribe}
        >
          <span>03</span> Souscrire en ligne
        </p>
      </div>
      {getTopbarContratInfoDiv()}
    </div>
  );
}
