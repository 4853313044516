import {
  Button,
  Emphasis,
  FormAction,
  FormField,
  FormGrid,
  FormNotice,
  FormRow,
  FormTitle,
} from "@lya-protect/lya-protect-form-library/dist/UI";
import "./landing.scss";
import { NavButton } from "@src/NavButton";
import {
  Form,
  InputButtonGroup,
  InputCheckbox,
  InputMask,
  InputText,
  useFormConfig,
} from "@lya-protect/lya-protect-form-library/dist/FormAPI";
import { LoaderOverlay } from "@src/LoaderOverlay";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { displayToast } from "@src/Toaster";
import { captureException } from "@sentry/react";
import { zod } from "@lya-protect/lya-protect-form-library/dist/Exports";
import { environment } from "@src/MarqueBlanche";
import { StepProps } from "./Steps";

export type ContactInfosData = {
  civility?: "Monsieur" | "Madame";
  name?: string;
  surname?: string;
  mail?: string;
  acceptStorage?: boolean;
};
type ContactInfosProps = StepProps<ContactInfosData>;

const getConsentementString = () => {
  if (environment.is.default) {
    return "J'accepte le stockage et le traitement de mes données personnelles afin de pouvoir recevoir le devis demandé, d'être recontacté par Tutélaire et poursuivre le parcours.";
  }
  let consent =
    "J’accepte le stockage et le traitement de mes données personnelles afin de pouvoir recevoir le devis demandé et d'être recontacté par ";
  if (environment.is.MUTAMI) consent += "MUTAMI ";
  if (environment.is.MCA) consent += "la Mutuelle Complémentaire d’Alsace ";
  if (environment.is.MSP) consent += "la Mutuelle des Services Publics ";
  return `${consent}et poursuivre le parcours. J’accepte également l’accès à mes données à la mutuelle Tutélaire, en sa qualité de concepteur et d’assureur.`;
};

const YOUSIGN_NAME_REGEX =
  /^[a-zA-Z0-9àáâãäåæçèéêëìíîïñòóôõöùúûüýÿÀÁÂÃÄÅÇÈÉÊËÌÍÎÏÑÒÓÔÕÖÙÚÛÜÝ ß.’'()-]+$/;

function cleanMaskedInput(val: unknown) {
  if (typeof val !== "string") return undefined;
  return val.replace(/\s/g, "");
}
function cleanMaskedString(val: string) {
  return val.replace(/\s/g, "");
}

const schema = zod.object({
  isSir: zod.boolean({ required_error: "Champ requis" }),
  name: zod
    .string({ required_error: "Champ requis" })
    .min(1, { message: "Champ requis" })
    .max(95, "Nom trop long")
    .regex(YOUSIGN_NAME_REGEX, { message: "Nom invalide" }),
  surname: zod
    .string({ required_error: "Champ requis" })
    .min(1, { message: "Champ requis" })
    .max(95, "Prénom trop long")
    .regex(YOUSIGN_NAME_REGEX, { message: "Prénom invalide" }),
  mail: zod
    .string({ required_error: "Champ requis" })
    .min(1, { message: "Champ requis" })
    .email({ message: "Email Invalide" }),
  acceptStorage: zod
    .boolean({ required_error: "Champ requis" })
    .refine((val) => val === true, { message: "Champ requis" }),
  phoneNumber: zod.preprocess(
    cleanMaskedInput,
    zod
      .string({ required_error: "Champ requis" })
      .min(1, "Champ requis")
      .regex(/^06\d{8}|07[3-9]\d{7}$/, {
        message: "Numéro invalide",
      })
  ),
});
type FormValues = zod.infer<typeof schema>;

export function ContactInfos({
  changeStep,
  data,
  setData,
  stepsData,
  setProjetPreSouscription: setProject,
}: ContactInfosProps) {
  const formConfig = useFormConfig<typeof schema, FormValues>({
    schema,
    defaultValues: data,
  });
  const navigate = useNavigate();

  type CreateDevisResponse = { value: string };
  const handleSubmit = async (submittedData: FormValues) => {
    try {
      const { data: response } = await axios.post<CreateDevisResponse>(
        `//${process.env.VITE_URL_GATEWAY}/devis`,
        {
          besoin: {
            assure: {
              civilite: submittedData.isSir ? "Monsieur" : "Madame",
              nom: submittedData.name,
              prenom: submittedData.surname,
              contactMail: submittedData.mail,
              naissanceDate: stepsData.BIRTHDATE.birthDate,
              contactTelephone: cleanMaskedInput(submittedData.phoneNumber),
            },
            consentements: [
              {
                description: getConsentementString(),
                type: "CONSENTEMENT_TRAITEMENT_DONNEES",
              },
            ],
          },
          formuleName: stepsData.TARIF?.name,
          adherent: stepsData.SITUATION?.isMemberTutelaire,
        }
      );
      navigate(`/${response.value}`);
      setProject({
        contact: {
          birthDate: stepsData.BIRTHDATE.birthDate as Date,
          civility: submittedData.isSir ? "Monsieur" : "Madame",
          mail: submittedData.mail,
          name: submittedData.name,
          surname: submittedData.surname,
          mobile: cleanMaskedString(submittedData.phoneNumber),
        },
        tarif: {
          name: stepsData.TARIF?.name as string,
          simulationCotisation: stepsData.TARIF?.simulationCotisation as number,
        },
      });
      setData(submittedData);
      changeStep("CONFIRM_SIMULATION", "forward");
    } catch (err) {
      displayToast("Une erreur inconnue est survenue", "error");
      captureException(err);
      console.error(err);
      throw err;
    }
  };

  return (
    <>
      <LoaderOverlay
        isLoading={
          formConfig.formState.isSubmitting ||
          formConfig.formState.isSubmitSuccessful
        }
      />
      <div className="landing-step">
        <div className="stepper__centered-container landing-step__first-part">
          <div className="stepper__centered-content step__grid ">
            <NavButton
              onClick={() => {
                changeStep("RECAP", "backward");
              }}
            >
              Précédent
            </NavButton>
            <Form
              className="situation-step__form"
              formConfig={formConfig}
              onSubmit={handleSubmit}
            >
              <FormGrid>
                <FormTitle>
                  <h1>
                    Renseignez <Emphasis>vos coordonnées</Emphasis> pour
                    recevoir <Emphasis>votre devis</Emphasis>
                  </h1>
                </FormTitle>
                <FormNotice>
                  <Emphasis>*</Emphasis> Champs obligatoires
                </FormNotice>
                <FormRow>
                  <FormField>
                    <InputButtonGroup<FormValues, boolean>
                      name="isSir"
                      size="large"
                      options={[
                        { label: "Monsieur", value: true },
                        { label: "Madame", value: false },
                      ]}
                    />
                  </FormField>
                </FormRow>
                <FormRow>
                  <FormField>
                    <InputText name="name" required label="Nom" />
                  </FormField>
                </FormRow>
                <FormRow>
                  <FormField>
                    <InputText name="surname" required label="Prénom" />
                  </FormField>
                </FormRow>
                <FormRow>
                  <FormField>
                    <InputText<FormValues>
                      name="mail"
                      required
                      label="Adresse mail"
                    />
                  </FormField>
                </FormRow>
                <FormRow>
                  <FormField size="full">
                    <InputMask<FormValues>
                      mask="99 99 99 99 99"
                      name="phoneNumber"
                      label="Numéro de portable"
                      required
                    />
                  </FormField>
                </FormRow>
                <FormRow>
                  <FormField>
                    <p>
                      Afin de vous transmettre un devis, nous devons stocker et
                      traiter vos données personnelles. Si vous nous autorisez à
                      stocker vos données personnelles à cette fin, cochez la
                      case ci-dessous.
                    </p>
                  </FormField>
                </FormRow>
                <FormRow>
                  <FormField>
                    <InputCheckbox
                      name="acceptStorage"
                      required
                      label={getConsentementString()}
                    />
                  </FormField>
                </FormRow>
                <FormAction>
                  <Button
                    type="submit"
                    disabled={
                      formConfig.formState.isSubmitting ||
                      formConfig.formState.isSubmitSuccessful
                    }
                  >
                    Envoyer
                  </Button>
                </FormAction>
              </FormGrid>
            </Form>
          </div>
        </div>
      </div>
    </>
  );
}
