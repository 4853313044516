import {
  Button,
  Emphasis,
  FormAction,
  FormField,
  FormGrid,
  FormRow,
} from "@lya-protect/lya-protect-form-library/dist/UI";
import "./consent.scss";
import {
  Form,
  InputCheckbox,
  useFormConfig,
} from "@lya-protect/lya-protect-form-library/dist/FormAPI";
import { NavButton } from "@src/NavButton";
import { zod } from "@lya-protect/lya-protect-form-library/dist/Exports";
import { environment } from "@src/MarqueBlanche";
import { StepProps } from "./Steps";

const getConsentContactOffre = () => {
  if (environment.is.MUTAMI) {
  } else {
    return "J'accepte d'être contacté dans le cadre du développement des offres et des services Tutélaire, afin de recevoir des informations portant sur les contrats ou la vie de la mutuelle";
  }
};
export const SOUHAIT_ADHERER =
  "Vous souhaitez adhérer au contrat sâge autonomie proposé par Tutélaire";
export const CONSENT_DEMATERIALISE =
  "Vous acceptez de recevoir la documentation et signer votre bulletin d'adhésion au format dématérialisé, le parcours de souscription en ligne étant entièrement digitalisé";
export const CONSENT_CONTACT_OFFFRE = () => {
  if (environment.is.MUTAMI) {
    return (
      "J'autorise Mutami et son partenaire Tutélaire à me recontacer dans le cadre de mon adhésion et à m'envoyer des informations portant sur les offres, services et" +
      " nouveautés relatives à mes contrats."
    );
  }
  if (environment.is.MSP) {
    return "J'autorise la Mutuelle des Services Publics et son partenaire Tutélaire à me recontacer dans le cadre de mon adhésion et à m'envoyer des informations portant sur les offres, services et nouveautés relatives à mes contrats.";
  }
  return "J'accepte d'être contacté dans le cadre du développement des offres et des services Tutélaire, afin de recevoir des informations portant sur les contrats ou la vie de la mutuelle";
};
export const CONSENTEMENT_AVERTISSEMENT =
  "Vous reconnaissez avoir pris connaissance des avertissements suivants : - Les éléments que vous avez renseignés sont complets, sincères et exacts  - Vous reconnaissez avoir reçu une information sur l'étendue, la définition des risques et des garanties proposées à travers le document d'information normalisé sur le produit d'assurance « DIPA » et le « Règlement » sâge autonomie y compris ses annexes. - Vous vous engagez à informer Tutélaire de toute modification concernant votre situation personnelle - Vous reconnaissez avoir été informé(e) que Tutélaire est susceptible de ne pas vous fournir l'intégralité des services qu'il propose dans le cas où vous refuseriez de compléter tout ou partie du présent document - Par ailleurs, vous reconnaissez avoir été informé par Tutélaire qu'une fausse déclaration ou réticence peut entraîner la nullité de votre contrat (L. 221-14 du code de la mutualité) ou la majoration de la cotisation (L. 221-15 du code de la mutualité)";

const schema = zod.object({
  souhaitAdherer: zod
    .boolean({ required_error: "Champ requis" })
    .refine((v) => v === true, "Champ requis"),
  consentDemat: zod
    .boolean({ required_error: "Champ requis" })
    .refine((v) => v === true, "Champ requis"),
  consentAvertissement: zod
    .boolean({ required_error: "Champ requis" })
    .refine((val) => val === true, "Champ requis"),
  consentContactOffre: zod.boolean().optional(),
});

export type ConsentFormValues = zod.infer<typeof schema>;

type ConsentProps = StepProps<ConsentFormValues>;

export function Consent({ changeStep, setData, data }: ConsentProps) {
  const formConfig = useFormConfig<typeof schema, ConsentFormValues>({
    schema,
    defaultValues: data,
  });

  const handleSubmit = (submittedData: ConsentFormValues) => {
    setData(submittedData);
    changeStep("ABOUT_YOU", "forward");
  };

  return (
    <div className="consent-step">
      <div className="stepper__centered-container">
        <div className="stepper__centered-content step__grid step__grid--padding-bottom">
          <NavButton
            onClick={() => {
              changeStep("ELIGIBILITE", "backward");
            }}
          >
            Précédent
          </NavButton>
          <h1 className="consent-step__title">
            Avant de poursuivre, merci de lire les points suivants
          </h1>
          <p className="consent-step__notice">
            <Emphasis>*</Emphasis> Mention obligatoire
          </p>
          <div className="consent-step__padded-content">
            <Form
              className="consent-step__form"
              formConfig={formConfig}
              onSubmit={handleSubmit}
            >
              <FormGrid>
                <FormRow>
                  <FormField>
                    <InputCheckbox<ConsentFormValues>
                      name="souhaitAdherer"
                      required
                      label={SOUHAIT_ADHERER}
                    />
                  </FormField>
                </FormRow>
                <FormRow>
                  <FormField>
                    <InputCheckbox<ConsentFormValues>
                      name="consentDemat"
                      required
                      label={CONSENT_DEMATERIALISE}
                    />
                  </FormField>
                </FormRow>
                <FormRow>
                  <FormField>
                    <InputCheckbox<ConsentFormValues>
                      name="consentAvertissement"
                      label={
                        <>
                          <p>
                            Vous reconnaissez avoir pris connaissance des
                            avertissements suivants&nbsp;:
                          </p>
                          <ul className="consent-souscription-step__exclusions-list">
                            <li>
                              Les éléments que vous avez renseignés sont
                              complets, sincères et exacts
                            </li>
                            <li>
                              Vous reconnaissez avoir reçu une information sur
                              l&apos;étendue, la définition des risques et des
                              garanties proposées à travers le{" "}
                              <a
                                tabIndex={-1}
                                target="_blank"
                                href={environment.current.docDIPA}
                                rel="noreferrer"
                              >
                                document d&apos;information sur le produit
                                d&apos;assurance
                              </a>{" "}
                              « DIPA » et le «{" "}
                              <a
                                tabIndex={-1}
                                target="_blank"
                                href={environment.current.docReglement}
                                rel="noreferrer"
                              >
                                Règlement
                              </a>{" "}
                              » sâge autonomie y compris ses annexes.
                            </li>
                            <li>
                              Vous vous engagez à informer Tutélaire de toute
                              modification concernant votre situation
                              personnelle
                            </li>
                            <li>
                              Vous reconnaissez avoir été informé(e) que
                              Tutélaire est susceptible de ne pas vous fournir
                              l&apos;intégralité des services qu&apos;il propose
                              dans le cas où vous refuseriez de compléter tout
                              ou partie du présent document
                            </li>
                            <li>
                              Par ailleurs, vous reconnaissez avoir été informé
                              par Tutélaire qu&apos;une fausse déclaration ou
                              réticence peut entraîner la nullité de votre
                              contrat (L. 221-14 du code de la mutualité) ou la
                              majoration de la cotisation (L. 221-15 du code de
                              la mutualité)
                              <Emphasis>*</Emphasis>
                            </li>
                          </ul>
                        </>
                      }
                    />
                  </FormField>
                </FormRow>
                <FormRow>
                  <FormField>
                    <InputCheckbox<ConsentFormValues>
                      name="consentContactOffre"
                      label={CONSENT_CONTACT_OFFFRE()}
                    />
                  </FormField>
                </FormRow>
                <FormAction>
                  <Button
                    type="submit"
                    disabled={
                      formConfig.formState.isSubmitting ||
                      formConfig.formState.isSubmitSuccessful
                    }
                  >
                    Suivant
                  </Button>
                </FormAction>
              </FormGrid>
            </Form>
          </div>
        </div>
      </div>
    </div>
  );
}
